<template>
  <section>
    <v-row no-gutters class="mt-6" v-if="haveRole('ROLE_UNIDAD_CONSOLIDADORA')">
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          label="Unidad solicitante"
          :items="ctl_unidad_solicitante_var"
          item-text="nombre"
          item-value="id"
          v-model="search_unidad_solicitante_var"
          outlined
          hide-details
          @change="SearchUnidadSolicitanteFtn"
          clearable
        />
      </v-col>
    </v-row>
    <!--  Componente dinamico global que recibe props como el datatable, para mayor informacion verificar el archivo declarado -->
    <data-table-component
      :headers="HEADERS_CONST"
      :items="list_solic_aprob_st"
      :total_registros="total_rows_cmp"
      @paginar="PaginateFtn"
      v-models:pagina="page_cmp"
      v-models:select="cant_per_page_cmp"
      :show_actions="true"
      :actions="ACTIONS_CONST"
      @showDetail="ShowDetailFtn"
      @deleteSolicitud="OpenDialogFtn"
    >
      <!-- Slot monto asignado -->
      <template v-slot:[`item.monto_asignado`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto_asignado)
        }}
      </template>
      <template v-slot:[`item.codigo_proceso`]="{ item }">
        {{ item.codigo_proceso ?? "-" }}
      </template>
    </data-table-component>
    <!-- Modal de confirmacion -->
    <!--  Componente dinamico global de modal de validacion, para mayor informacion verificar el archivo declarado -->
    <confirmation-dialog-component
      :show="show_dialog_var"
      title="¿Deseas eliminar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(show_dialog_var = false), (id_solicitud_seleccionada_var = null)"
      @confirm="DeleteSolicitudFtn"
    />
  </section>
</template>
  
<script>
// importaciones de componentes globales
import DataTableComponent from "../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";

// importaciones de librerias
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        align: "start",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "start",
        text: "OBS",
        value: "obs",
      },
      {
        align: "right",
        text: "Monto ($)",
        value: "monto_asignado",
      },
      {
        align: "start",
        text: "No. Proceso",
        value: "codigo_proceso",
      },
      {
        align: "start",
        text: "Estado",
        value: "estado_solicitud",
      },
      {
        align: "center",
        text: "Acciones",
        value: "actions",
      },
    ],
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Ver detalles",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "showDetail",
      },
      {
        tooltip: {
          text: "Eliminar solicitud",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-trash-can",
        },
        eventName: "deleteSolicitud",
      },
    ],

    // variables
    id_solicitud_seleccionada_var: null,
    show_dialog_var: false,

    ctl_unidad_solicitante_var: [],
    search_unidad_solicitante_var: null,
  }),

  computed: {
    // mapeo de estados
    ...mapState("pac/SolicitudNecesidadStore", ["list_solic_aprob_st"]),
    ...mapState({
      cant_per_page_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_aprob_pag_st?.per_page,
      page_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_aprob_pag_st?.page,
      total_rows_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_aprob_pag_st
          ?.total_rows,
      id_unidad_cmp: (state) => state?.selectedUnidad?.id,
    }),
  },

  methods: {
    // mapeo de mutaciones
    ...mapMutations("pac/SolicitudNecesidadStore", [
      "ResetListSolicAprobMt",
      "ResetListSolicAprobPagMt",
    ]),

    // mapeo de acciones
    ...mapActions("pac/SolicitudNecesidadStore", [
      "FetchSolicitudesNecesidadesAc",
    ]),

    // Funciones regulares

    ShowDetailFtn(item) {
      this.$router.push({
        name: "detalle-solicitud-necesidad",
        params: {
          id_solicitud: item.id,
        },
      });
    },

    //  Funcion que abre el modal de confirmacion
    OpenDialogFtn(item) {
      this.id_solicitud_seleccionada_var = item?.id;
      this.show_dialog_var = true;
    },

    // Funciones asincronas
    //  Funcion que recibe los filtros de paginacion y realiza la peticion de solicitudes de necesidad aprobadas ( API )
    async PaginateFtn(filters_param_ext) {
      const { cantidad_por_pagina, pagina } = filters_param_ext;

      const filters_param = {
        page: pagina,
        per_page: cantidad_por_pagina,
        id_estado: "2,3,7", // Aprobadas y rechazadas
        id_unidad: this.search_unidad_solicitante_var || undefined,
      };

      await this.FetchSolicitudesNecesidadesAc(filters_param);
    },

    //  Funcion que elimina una solicitud de necesidad seleccionada atraves de su id ( API )
    async DeleteSolicitudFtn() {
      const response = await this.services.PacServices.deleteSolicitudesPAC(
        this.id_solicitud_seleccionada_var
      );

      if (response) {
        this.temporalAlert({
          message: "Solicitud eliminada correctamente",
          show: true,
          type: "success",
        });

        // refresh de la tabla de solicitudes de necesidades aprobadas
        this.PaginateFtn({
          cantidad_por_pagina: this.cant_per_page_cmp,
          pagina: this.page_cmp,
        });
      }

      // reseteo de variables
      this.show_dialog_var = false;
      this.id_solicitud_seleccionada_var = null;
    },

    async FetchUnidadesSolicitantesFtn() {

      const { status, data } =
        await this.services.UnidadesServices.getUnidadesDependientes(
          this.id_unidad_cmp
        );
      if (status == 200) {
        this.ctl_unidad_solicitante_var = data;
      }

    },

    async SearchUnidadSolicitanteFtn() {

      const filters_param = {
        page: 1,
        per_page: 10,
        id_estado: "2,3,7",
        id_unidad: this.search_unidad_solicitante_var,
      };

      await this.FetchSolicitudesNecesidadesAc(filters_param);

    },
  },

  created() {
    //  Se realiza la peticion de solicitudes de necesidad aprobadas inicializadas ( API )
    this.PaginateFtn({
      cantidad_por_pagina: this.cant_per_page_cmp,
      pagina: this.page_cmp,
    });
    if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
      this.FetchUnidadesSolicitantesFtn();
    }
  },

  beforeDestroy() {
    this.ResetListSolicAprobMt();
    this.ResetListSolicAprobPagMt();
  },
};
</script>
<!-- Componente table que lista las solicitudes aprobadas -->