<template>
  <div class="mt-9 mt-md-0">
    <v-tabs
      v-model="tab_value_var"
      color="secondary"
      show-arrows
      background-color="bgMinsal"
    >
      <v-tab
        v-for="(item, index) in OPTIONS_TAB_CONST"
        :key="index"
        class="text-no-style secondary--text"
      >
        <v-icon color="secondary"> {{ item.icon }} </v-icon>
        <span class="ml-2" v-text="item.text" />
        <v-chip v-if="item.counter" class="ml-2 px-2" dark color="secondary">
          {{ counter_pendt_cmp.toString().padStart(2, '0') }}
        </v-chip>
      </v-tab>
    </v-tabs>
    <v-tabs-items class="mt-4 mx-3" :value="tab_value_var" style="width: 100%">
      <div v-for="(item, index) in OPTIONS_TAB_CONST" :key="index">
        <v-tab-item>
          <component :is="item.content" />
        </v-tab-item>
      </div>
    </v-tabs-items>
  </div>
</template>
  
<script>
// importaciones de componentes locales
import TableSolicitudesPendientesComponent from "./TableSolicitudesPendientesComponent.vue";
import TableSolicitudesAprobadasComponent from "./TableSolicitudesAprobadasComponent.vue";
import { mapState } from "vuex";

export default {
  components: {
    TableSolicitudesPendientesComponent,
    TableSolicitudesAprobadasComponent,
  },

  data: () => ({
    // constantes
    OPTIONS_TAB_CONST: [
      {
        content: TableSolicitudesPendientesComponent,
        icon: "mdi-account-multiple",
        text: "Pendientes de aprobar",
        counter: true,
        value: 0,
      },
      {
        content: TableSolicitudesAprobadasComponent,
        icon: "mdi-package-variant-closed",
        text: "Solicitudes aprobadas",
        counter: false,
        value: 1,
      },
    ],

    // variables
    tab_value_var: 0,
  }),
  computed: {
    // mapeo de total_rows como counter_pendt_cmp ( solicitudes pendientes )
    ...mapState({
      counter_pendt_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_pendt_pag_st
          ?.total_rows,
    }),
  },
};
</script>
<!-- Componente contenedor de tables de solicitude necesidades -->