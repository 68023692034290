<template>
  <section>
    <v-row no-gutters class="mt-6" v-if="haveRole('ROLE_UNIDAD_CONSOLIDADORA')">
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          label="Unidad solicitante"
          :items="ctl_unidad_solicitante_var"
          item-text="nombre"
          item-value="id"
          v-model="search_unidad_solicitante_var"
          outlined
          hide-details
          @change="SearchUnidadSolicitanteFtn"
          clearable
        />
      </v-col>
    </v-row>
    <!--  Componente dinamico global que recibe props como el datatable, para mayor informacion verificar el archivo declarado -->
    <data-table-component
      table_title="Listado de solicitudes pendientes de aprobar"
      :headers="HEADERS_CONST"
      :items="list_solic_pendt_st"
      :total_registros="total_rows_cmp"
      @paginar="PaginateFtn"
      v-models:pagina="page_cmp"
      v-models:select="cant_per_page_cmp"
      :show_actions="true"
      :actions="ACTIONS_CONST"
      @showDetail="ShowDetailFtn"
      @aprobarSolicitud="OpenDialogAprobarFtn"
      @rechazarSolicitud="OpenDialogRechazarFtn"
    >
      <!-- Slot monto asignado -->
      <template v-slot:[`item.monto_asignado`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.fondos_ajuste ?? item.monto_asignado)
        }}
      </template>
      <template v-slot:[`item.codigo_proceso`]="{ item }">
        {{ item.codigo_proceso ?? "-" }}
      </template>
    </data-table-component>
    <!-- Modales de confirmacion de aprobacion y rechazo  -->
    <!--  Componentes dinamicos globales de modal de validacion, para mayor informacion verificar el archivo declarado -->
    <confirmation-dialog-component
      :show="show_dialog_aprobar_var"
      title="¿Deseas aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="
        (show_dialog_aprobar_var = false),
          (id_solicitud_seleccionada_var = null)
      "
      @confirm="AprobarSolicitudFtn"
    />
    <confirmation-dialog-component
      :show="show_dialog_rechazar_var"
      title="¿Deseas rechazar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="
        (show_dialog_rechazar_var = false),
          (id_solicitud_seleccionada_var = null)
      "
      @confirm="RechazarSolicitudFtn"
    />
  </section>
</template>
  
  <script>
// importaciones de componentes globales
import DataTableComponent from "../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";

// importaciones de librerias
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        align: "start",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "start",
        text: "OBS",
        value: "obs",
      },
      {
        align: "right",
        text: "Monto ($)",
        value: "monto_asignado",
      },
      {
        align: "start",
        text: "Estado solicitud",
        value: "estado_solicitud",
      },
      {
        align: "center",
        text: "Acciones",
        value: "actions",
      },
    ],
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Ver detalles",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "showDetail",
      },
      {
        tooltip: {
          text: "Aprobar solicitud",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "green",
          name: "mdi-check-circle-outline",
        },
        eventName: "aprobarSolicitud",
      },
      {
        tooltip: {
          text: "Rechazar solicitud",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "red",
          name: "mdi-close-circle-outline",
        },
        eventName: "rechazarSolicitud",
      },
    ],

    // variables
    show_dialog_aprobar_var: false,
    show_dialog_rechazar_var: false,
    id_solicitud_seleccionada_var: null,

    ctl_unidad_solicitante_var: [],
    search_unidad_solicitante_var: null,
  }),

  computed: {
    // mapeo de estados
    ...mapState("pac/SolicitudNecesidadStore", ["list_solic_pendt_st"]),
    ...mapState({
      cant_per_page_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_pendt_pag_st?.per_page,
      page_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_pendt_pag_st?.page,
      total_rows_cmp: (state) =>
        state?.pac?.SolicitudNecesidadStore?.list_solic_pendt_pag_st
          ?.total_rows,
      id_unidad_cmp: (state) => state?.selectedUnidad?.id,
    }),
  },

  methods: {
    // mapeo de mutaciones
    ...mapMutations("pac/SolicitudNecesidadStore", [
      "ResetListSolicPendtMt",
      "ResetListSolicPendtPagMt",
    ]),

    // mapeo de acciones
    ...mapActions("pac/SolicitudNecesidadStore", [
      "FetchSolicitudesNecesidadesAc",
    ]),

    // Funciones regulares

    ShowDetailFtn(item) {
      if (!item.fondos_ajuste) {
        this.$router.push({
          name: "detalle-solicitud-necesidad",
          params: {
            id_solicitud: item.id,
          },
        });
      } else {
        this.$router.push({
          name: "ajuste-necesidades",
          params: {
            idSolicitudNecesidad: item.id,
            onlyView: 1,
          },
        });
      }
    },

    //  Funcion que abre el modal de confirmacion de aprobacion de solicitud
    OpenDialogAprobarFtn(item) {
      this.id_solicitud_seleccionada_var = item;
      this.show_dialog_aprobar_var = true;
    },

    //  Funcion que abre el modal de confirmacion de rechazo de solicitud
    OpenDialogRechazarFtn(item) {
      this.id_solicitud_seleccionada_var = item;
      this.show_dialog_rechazar_var = true;
    },

    // Funciones asincronas
    //  Funcion que recibe los filtros de paginacion y realiza la peticion de listado de solicitudes de necesidades pendientes ( API )
    async PaginateFtn(filters_param_ext) {
      const { cantidad_por_pagina, pagina } = filters_param_ext;

      const filters_param = {
        page: pagina,
        per_page: cantidad_por_pagina,
        id_estado: "1,6",
        id_unidad: this.search_unidad_solicitante_var || undefined,
      };

      await this.FetchSolicitudesNecesidadesAc(filters_param);
    },

    //  Funcion que realiza la peticion de aprobacion de solicitud de necesidad ( API )
    async AprobarSolicitudFtn() {
      const response = await this.services.PacServices.putEstadoSolicitud(
        this.id_solicitud_seleccionada_var.id,
        {
          id_estado:
            this.id_solicitud_seleccionada_var.id_estado_solicitud == 6 ? 7 : 2,
        }
      );

      if (response) {
        this.temporalAlert({
          message: "Solicitud aprobada correctamente",
          show: true,
          type: "success",
        });

        // refresh de la tabla de solicitudes de necesidades pendientes y aprobadas
        this.PaginateFtn({
          cantidad_por_pagina: this.cant_per_page_cmp,
          pagina: this.page_cmp,
        });

        const filters_param = {
          page: 1,
          per_page: 10,
          id_estado: "2,3,7",
        };

        await this.FetchSolicitudesNecesidadesAc(filters_param);
      }

      // reseteo de variables
      this.show_dialog_aprobar_var = false;
      this.id_solicitud_seleccionada_var = null;
    },

    //  Funcion que realiza la peticion de rechazo de solicitud de necesidad ( API )
    async RechazarSolicitudFtn() {
      const response = await this.services.PacServices.putEstadoSolicitud(
        this.id_solicitud_seleccionada_var.id,
        {
          id_estado: 3,
        }
      );

      if (response) {
        this.temporalAlert({
          message: "Solicitud rechazada correctamente",
          show: true,
          type: "success",
        });

        // refresh de la tabla de solicitudes de necesidades pendientes y aprobadas
        this.PaginateFtn({
          cantidad_por_pagina: this.cant_per_page_cmp,
          pagina: this.page_cmp,
        });

        const filters_param = {
          page: 1,
          per_page: 10,
          id_estado: "2,3,7",
        };

        await this.FetchSolicitudesNecesidadesAc(filters_param);
      }

      // reseteo de variables
      this.show_dialog_rechazar_var = false;
      this.id_solicitud_seleccionada_var = null;
    },

    async FetchUnidadesSolicitantesFtn() {

      const { status, data } =
        await this.services.UnidadesServices.getUnidadesDependientes(
          this.id_unidad_cmp
        );
      if (status == 200) {
        this.ctl_unidad_solicitante_var = data;
      }

    },

    async SearchUnidadSolicitanteFtn() {

      const filters_param = {
        page: 1,
        per_page: 10,
        id_estado: "1,6",
        id_unidad: this.search_unidad_solicitante_var,
      };

      await this.FetchSolicitudesNecesidadesAc(filters_param);

    },
  },

  created() {
    //  Se realiza la peticion de solicitudes de necesidad aprobadas inicializadas ( API )
    this.PaginateFtn({
      cantidad_por_pagina: this.cant_per_page_cmp,
      pagina: this.page_cmp,
    });

    if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
      this.FetchUnidadesSolicitantesFtn();
    }
  },

  beforeDestroy() {
    this.ResetListSolicPendtMt();
    this.ResetListSolicPendtPagMt();
  },
};
</script>
<!-- Componente table que lista las solicitudes pendientes de aprobación -->