<template>
  <section>
    <!-- titulo -->
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span
        class="text-h5 secondary--text"
        v-text="'Solicitudes de necesidades'"
      />
      <span class="" v-text="'Ejercicio ' + anioFiscal" />
    </div>
    <!-- container -->
    <container-list-component />
  </section>
</template>

<script>
// importacion de librerias
import { mapState } from "vuex";

// importacion de componentes locales
import ContainerListComponent from "./components/ContainerListComponent.vue";

export default {
  components: {
    ContainerListComponent,
  },
  
  computed: {
    // mapeo de variables del store
    ...mapState(["userInfo", "anioFiscal"]),
  },
};
</script>
<!-- Vista de listado de solicitudes de necesidades pendientes y aprobadas -->